import { Container } from "@mui/material";
import FinancingComponent from "../../Components/Financing/FinancingComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
const Financing = () => {
  return (
    <>
      <DashboardLayout active_tab="Financing">
        <Container maxWidth="md">
          <FinancingComponent />
        </Container>
      </DashboardLayout>
    </>
  );
};
export default Financing;
