import {Box} from '@mui/system'
import {Financing_Content} from '../../Utils/text_content'
import {images} from '../../Assets/Img'
const FinancingComponent = () => {
  return (
    <>
      <Box className="Financing_Title">{Financing_Content?.FINANCING}</Box>
      <Box>
        <img src={images.Finance} alt="" className="Financing_bannerImg" />
      </Box>
      <Box className="Financing_Description" sx={{marginTop: '0px'}}>
        <Box className="Financing_Desing_Title">
          {Financing_Content?.FINANCING_AVAILABLE}{' '}
          <span className="Footer_Address_Desing_info_highlight">
            {Financing_Content?.US_EQUITY_FUNDING}
          </span>
        </Box>

        <Box className="Financing_Desing_info">{Financing_Content?.Name} </Box>
        <Box className="Financing_Desing_info">
          {Financing_Content?.Position}{' '}
        </Box>
        <Box className="Financing_Desing_info">{Financing_Content?.Post} </Box>
        <Box className="Financing_Desing_info">
          {Financing_Content?.ONE_INTERNATIONAL_PLACE}{' '}
        </Box>
        <Box className="Financing_Desing_info">
          {Financing_Content?.BOSTON}{' '}
        </Box>
        <Box className="Financing_Desing_info">
          {Financing_Content?.OFFICE}{' '}
        </Box>
        <Box className="Financing_Desing_info">{Financing_Content?.CELL} </Box>
        <Box className="Financing_Desing_info">{Financing_Content?.FAX} </Box>

        <Box className="Financing_Desing_info_Email">
          {Financing_Content?.EMAIL}
        </Box>
      </Box>
    </>
  )
}
export default FinancingComponent
