import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import {Box} from '@mui/system'

import DashboardLayout from '../../Layout/DashboardLayout/DashboardLayout'
import MachineList from '../../Components/CommonComponent/MachineList/MachineList'
// import MachineImg from '../../Assets/Img/Machine.jpg'
import {Dashboard_Content} from '../../Utils/text_content'
import {images} from '../../Assets/Img'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {Carousel} from 'react-responsive-carousel'

const CarousalImages = [images.Machine1, images.Machine2, images.Machine3]

const Dashboard = () => {
  return (
    <DashboardLayout active_tab="Home">
      <Container maxWidth="md">
        <Box className="bannerImg_Box">
          {/* <img src={images.Machine} alt="" className="bannerImg" /> */}
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            centerMode={false}
            showStatus={false}
            interval={20000}
            showArrows={false}
          >
            {CarousalImages?.map((item, index) => {
              return <img src={item} alt="" className="bannerImg" />
            })}
          </Carousel>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box className="Dashboard_Box">
          <Grid container spacing={2}>
            <Grid item md={12} lg={7} sx={{margin: 'auto'}}>
              <Box className="Dashboard_Home_Text">
                {Dashboard_Content?.EQUIPMENT_INTERNATIONAL}
              </Box>
              <Box className="Dashboard_Home_Sub_Text">
                {Dashboard_Content?.CONSTRUCTION_EQUIPMENT_ANYWHERE}
              </Box>
              <Box className="Dashboard_Home_Sub_Text_Slogan">
                "{Dashboard_Content?.EVERYTHING_CONSTRUCTION}"
              </Box>
            </Grid>

            {/* <Grid item md={12} lg={5}>
              <Box className="Dashboard_Item_Title">
                {Dashboard_Content?.LATEST_EQUIPMENT_LISTINGS}
              </Box>
              <MachineList />
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </DashboardLayout>
  )
}
export default Dashboard
