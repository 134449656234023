import {Container} from '@mui/material'
import {Box} from '@mui/system'
import {Dashboard_Content, Address_List} from '../../../Utils/text_content'

const AddressBox = ({title}) => {
  return <Box className="Footer_Address_Text">{title}</Box>
}

const AddressBoxRender = () => {
  return Address_List.map(item => {
    return <AddressBox title={item?.title} />
  })
}

const Footer = () => {
  return (
    <>
      <Container maxWidth="md" sx={{marginBottom:'10px'}}>
        <AddressBoxRender />
        {/* <Box className="Footer_Address_Desing_info">
          {Dashboard_Content?.DESIGN_AND_HOSTING}{' '}
          <span className="Footer_Address_Desing_info_highlight">
            {Dashboard_Content?.GUHRU_STUDIO}
          </span>
        </Box> */}
      </Container>
    </>
  )
}
export default Footer
