import ContactForm from "../../Components/Contact_US/contactForm/contactForm";
import Map from "../../Components/Contact_US/map/map";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
const ContactUs = () => {
  return (
    <>
      <DashboardLayout active_tab="ContactUs">
        <div className="contact_us_page_background">
          <Map />
          <ContactForm />
        </div>
      </DashboardLayout>
    </>
  );
};
export default ContactUs;
