import {Box} from '@mui/system'
import {About_Content} from '../../Utils/text_content'
import {images} from '../../Assets/Img'
import Grid from '@mui/material/Grid'
const AboutUSComponent = () => {
  return (
    <>
      <Box className="Financing_Title">{About_Content?.ABOUT_US}</Box>
      <Box className="Financing_Description">
        <Grid container spacing={2}>
          <Grid item md={12} lg={5}>
            <img src={images.AboutUs} alt="" className="AboutUS_bannerImg" />
          </Grid>
          <Grid item md={12} lg={7}>
            <Box className="Financing_Dec">
              Dallas Robertson is owner and founder of Robertson Equipment
              International. With over 20 years of heavy construction equipment
              experience, he brings the knowledge, passion, and leadership
              necessary to fuel the continued growth, development. and success
              of the industry.
            </Box>
            <Box className="Financing_Dec">
              In his youth, Dallas developed his skills to operate and maintain
              heavy equipment on job-sites. Through his experiences in countless
              land-clearing jobs in North Carolina, he mastered the proper daily
              maintenance needs and optimal operation performance of heavy
              equipment. It is also here that his obsession for construction
              machinery was born.
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box className="Financing_Dec">
              Dallas earned his BS degree in Business and Marketing from High
              Point University in High Point, North Carolina. After college he
              moved to Charleston, South Carolina, where he worked for Sunbelt
              Rentals and Blanchard Caterpillar. This gave him the opportunity
              to further expand his knowledge of the many facets of the heavy
              construction equipment industry, ultimately leading him to start
              his own company.
            </Box>
            <Box className="Financing_Dec">
              Robertson Equipment International mission is to provide
              trustworthy and dependable services to our customers by creating a
              successful partnership with them throughout the buying or selling
              process. Our pledge is to establish a lasting relationship with
              our customers by exceeding their expectations and gaining their
              trust through exceptional service.
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default AboutUSComponent
