import MachineImg from '../Assets/Img/Machine.jpg'

export const ApplicationId = '8CA3D0F6-016E-4A6F-BDB3-FF2D07C074CB'

export const ReCAPTCHA_sitekey = '6LdGI8MiAAAAACcTvJTaF6FkJtSQxP2grVBFOFYp'

export const Dashboard_Content = {
  EQUIPMENT_INTERNATIONAL:
    'Welcome to the home of Robertson Equipment International!',
  CONSTRUCTION_EQUIPMENT_ANYWHERE:
    'Roberston Equiment International is your single source for heavy construction equipment anywhere in the world.',
  EVERYTHING_CONSTRUCTION: 'We buy and sell everything construction.',
  LATEST_EQUIPMENT_LISTINGS: 'Latest Equipment Listings',
  DESIGN_AND_HOSTING: 'Site Design and Hosting by',
  GUHRU_STUDIO: 'Guhru Studio',
  LARGER_VIEW: 'Click on images for Larger view',
}

export const Address_List = [
  {
    title: '© 2019 Roberston Equipment International.',
  },
  {
    title: '344 Winningham Rd,',
  },
  {
    title: 'Reevesville, SC 29471,USA',
  },
  {
    title: 'Office 843.608.3486',
  },
  {
    title: 'Fax 843.410.5644',
  },
]

export const Dashboard_Item_List = [
  {
    img: MachineImg,
    title: 'Mack DM6855',
    description:
      "1979 Tandom Axel National 666B Crane 78' boom comes with 25' Jib Air Cooled Duetz Engine tht runs boom",
  },
  {
    img: MachineImg,
    title: 'Mack DM6855',
    description:
      "1979 Tandom Axel National 666B Crane 78' boom comes with 25' Jib Air Cooled Duetz Engine tht runs boom",
  },
  {
    img: MachineImg,
    title: 'Mack DM6855',
    description:
      "1979 Tandom Axel National 666B Crane 78' boom comes with 25' Jib Air Cooled Duetz Engine tht runs boom",
  },
  {
    img: MachineImg,
    title: 'Mack DM6855',
    description:
      "1979 Tandom Axel National 666B Crane 78' boom comes with 25' Jib Air Cooled Duetz Engine tht runs boom",
  },
]

export const Equipment_List = [
  {
    ID: 0,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
  {
    ID: 2,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
  {
    ID: 3,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
  {
    ID: 4,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
  {
    ID: 5,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
  {
    ID: 6,
    img: MachineImg,
    title: 'Mack DM6855',
    details: [
      '2010',
      'EC24C LR',
      "60' stick",
      'Clean out Bkt',
      '1500hrs',
      'Like New Condition',
      'Loaded w/ factory options',
    ],
  },
]

export const MachineDetails_Content = {
  title: 'Mack DM6855',
  img: MachineImg,
  details: [
    '2010',
    'EC24C LR',
    "60' stick",
    'Clean out Bkt',
    '1500hrs',
    'Like New Condition',
    'Loaded w/ factory options',
  ],
  Equiment_Data: [
    {
      Equiment_title: 'Equiment Price :',
      Equiment_SUbTitle: '$45,000',
    },
    {
      Equiment_title: 'Equiment  Hours :',
      Equiment_SUbTitle: '5670',
    },
    {
      Equiment_title: 'Equiment Condition :',
      Equiment_SUbTitle: 'Excellent',
    },
    {
      Equiment_title: 'Equiment Location :',
      Equiment_SUbTitle: 'Nc',
    },
  ],
  related_img: [
    {img: MachineImg},
    {img: MachineImg},
    {img: MachineImg},
    {img: MachineImg},
  ],
}

export const Contact_Details = {
  PHONE_NUM: '+18434604584',
  PHONE_NUM_STRING: '+1 843-460-4584',
  EMAIL: 'sales@heavyequipmentco.com',
  OFFICE_LOCATION: '344 Winningham Rd, Reevesville, SC 29471, USA',
  FAX: '+18434105644',
  FAX_STRING: '+1 843-410-5644',
}

export const Contact_Content = {
  CONTACT_US: 'CONTACT US',
  CONTACT_FORM: 'CONTACT FORM',
  CONTACT_INFO: 'Contact Info',
  SECURITY_PURPOSES:
    'For security purposes, please enter the characters from the box above:',
}

export const Financing_Content = {
  FINANCING: 'Financing',
  FINANCING_AVAILABLE: 'Financing is available throgh our partner',
  US_EQUITY_FUNDING: 'US Equity Funding, LLC',
  Name: 'James E. Jones',
  Position: 'Vice President',
  Post: 'Regional Sales Manager',
  BOSTON: '10715 David Taylor Drive Suite 550',
  OFFICE: 'Charlotte, NC 28262',
  FAX: 'Fax    704-549-0410',
  CELL: 'Cell    704-236-7954',
  EMAIL: 'jjones19@mtb.com',
}

export const About_Content = {
  ABOUT_US: 'About Us',
}
