import {Box, Container} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {Link, useNavigate} from 'react-router-dom'
import {images} from '../../../Assets/Img'

const Header = ({active_tab}) => {
  const navigate = useNavigate()
  return (
    <div>
      {/* <div className="header_back" /> */}
      <Box onClick={() => navigate('/')}>
        <img src={images.Logo} alt="" className="Header_Logo" />
      </Box>
      <Container maxWidth="md">
        <Box className="DashboardLayout_Bottom"> </Box>
      </Container>

      <Box className="DashboardLayout_Navbar">
        <Box
          className={
            active_tab === 'Home'
              ? 'DashboardLayout_Header_Link_Active'
              : 'DashboardLayout_Header_Link'
          }
          onClick={() => navigate('/')}
        >
          Home
        </Box>
        <div className="border_divider" />
        <Box
          className={
            active_tab === 'AboutUS'
              ? 'DashboardLayout_Header_Link_Active'
              : 'DashboardLayout_Header_Link'
          }
          onClick={() => navigate('/AboutUS')}
        >
          About Us
        </Box>
        <div className="border_divider" />
        {/* <Box class="dropdown">
          <Box
            className={
              active_tab === "Equipment"
                ? "DashboardLayout_Header_Link_Active dropbtn"
                : "DashboardLayout_Header_Link dropbtn"
            }
            onClick={() => navigate("/Equipment")}
          >
            Equipment
          </Box>
          <Box class="dropdown-content">
            <Box>Link 1</Box>
            <Box>Link 2</Box>
            <Box>Link 3</Box>
          </Box>
        </Box> */}
        {/* <Box className="DashboardLayout_Header_Link">Testimonials</Box> */}

        <Box
          className={
            active_tab === 'Financing'
              ? 'DashboardLayout_Header_Link_Active'
              : 'DashboardLayout_Header_Link'
          }
          onClick={() => navigate('/Financing')}
        >
          Financing
        </Box>
        <div className="border_divider" />
        {/* <Box className="DashboardLayout_Header_Link">Shipping</Box> */}
        <Box
          className={
            active_tab === 'ContactUs'
              ? 'DashboardLayout_Header_Link_Active'
              : 'DashboardLayout_Header_Link'
          }
          onClick={() => navigate('/ContactUs')}
        >
          Contact
        </Box>
      </Box>
      <Box className="DashboardLayout_Navbar_Mini_Screen">
        <Box class="dropdown">
          <Box className="DashboardLayout_Header_Link dropbtn">
            <MenuIcon />{' '}
          </Box>
          <Box class="dropdown-content Mini_screen_Drop_Down">
            <Box
              className="DashboardLayout_Header_Link_Mini_screen"
              onClick={() => navigate('/')}
            >
              Home
            </Box>
            <Box
              className="DashboardLayout_Header_Link_Mini_screen"
              onClick={() => navigate('/AboutUS')}
            >
              About Us
            </Box>
            {/* <Box
              className="DashboardLayout_Header_Link_Mini_screen"
              onClick={() => navigate("/Equipment")}
            >
              Equipment
            </Box>
            <Box className="DashboardLayout_Header_Link_Mini_screen">
              Testimonials
            </Box> */}
            <Box
              className="DashboardLayout_Header_Link_Mini_screen"
              onClick={() => navigate('/Financing')}
            >
              Financing
            </Box>
            {/* <Box className="DashboardLayout_Header_Link_Mini_screen">
              Shipping
            </Box> */}
            <Box
              onClick={() => navigate('/ContactUs')}
              className="DashboardLayout_Header_Link_Mini_screen"
            >
              Contact
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
export default Header
