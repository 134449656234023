import {Container} from '@mui/material'
import {Contact_Content, Contact_Details} from '../../../Utils/text_content.js'
const Map = () => {
  return (
    <>
      <div className="Map">
        <Container
          fixed
          style={{
            backgroundColor: '#ddd',
            paddingLeft: '0px',
          }}
        >
          <div className="Map_Box">
            <div className="Map_Contact">
              <div style={{height: '350px', width: '100%'}}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3338.315263242474!2d-80.60404648512505!3d33.20583026930457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fec5bad4a70f55%3A0x684ee71f68c48f00!2s344%20Winningham%20Rd%2C%20Reevesville%2C%20SC%2029471%2C%20USA!5e0!3m2!1sen!2sin!4v1671473900737!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="Map_Contact_Box">
              <div className="Contact_Title_Text">
                {Contact_Content?.CONTACT_INFO}
              </div>
              <div>
                <div className="flex">
                  <div className="Map_Contact_Title">Office Location:</div>
                  <div className="Map_Contact_Sub_Title">
                    {Contact_Details?.OFFICE_LOCATION}
                  </div>
                </div>
                <div className="flex">
                  <div className="Map_Contact_Title">Phone:</div>
                  <a
                    href={`callto:${Contact_Details?.PHONE_NUM}`}
                    className="Map_Contact_Sub_Title Map_Contact_Title_Link"
                  >
                    {Contact_Details?.PHONE_NUM_STRING}
                  </a>
                </div>
                <div className="flex">
                  <div className="Map_Contact_Title">Fax:</div>
                  {/* <a
                  href={`callto:${Contact_Details?.FAX}`}
                  className="Map_Contact_Sub_Title Map_Contact_Title_Link"
                > */}
                  <div className="Map_Contact_Sub_Title">
                    {Contact_Details?.FAX_STRING}
                  </div>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default Map
