import {Api} from './axios'
import * as url from '../Common/apiconstants'

const getAllMachine = async payload => {
  try {
    const response = await Api.postRequestApi(url.MACHINE_LIST, payload)
    return response
  } catch (error) {
    return {error}
  }
}
export {getAllMachine}

export const getMachineDetailsByID = async ID => {
  console.log('ID', ID)
  try {
    const response = await Api.getRequestApi(
      url.GET_MACHINES_DETAILS + `?assetAdvertisementId=${ID}`,
    )
    return response
  } catch (error) {
    return {error}
  }
}

export const postContactUs = async payload => {
  try {
    const response = await Api.postRequestApi(url.POST_CONTACT_US, payload)
    return response
  } catch (error) {
    return {error}
  }
}
