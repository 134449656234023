import {
  Contact_Content,
  ApplicationId,
  ReCAPTCHA_sitekey,
} from '../../../Utils/text_content'
import FormInput from '../../CommonComponent/formInput/formInput'
import {Formik} from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'
import {useRef, useState} from 'react'
import Grid from '@mui/material/Grid'
// import { postContactUs } from "../../../services/contactUsService";
import {} from '../../../Utils/text_content'
import {toast} from 'react-toastify'
import {Container} from '@mui/system'
import {postContactUs} from '../../../Api/apiHelper'
const ContactForm = () => {
  const [showCode, setShowCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const reCaptchaRef = useRef(null)
  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.firstname ?? ''
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.lastname ?? ''
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
              value?.email ?? ''
            }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ''
    }</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.phone ?? ''
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Zip</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.zip ?? ''
          }</td>
        </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">City</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.city ?? ''
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
              value?.message ?? ''
            }</td>
          </tr>
        </table>
      <div>
    </div>
  `
    setIsLoading(true)
    let payload = {
      HtmlContent: htmlForm,
      IsFromREI: true,
    }
    postContactUs(payload)
      .then(res => {
        if (res.statusCode === 200) {
          toast.success(res?.data)
          reCaptchaRef?.current?.reset()
          resetForm()
        } else {
          toast.error(res?.data)
        }
        setIsLoading(false)
      })
      .catch(err => {
        toast.error(err?.data)
        setIsLoading(false)
      })
  }

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required('Please Enter First Name'),
    lastname: Yup.string().required('Please Enter Last Name'),
    email: Yup.string()
      .email('Enter Valid Email Address')
      .required('Please Enter E-mail Address'),

    phone: Yup.string().required('Please Enter Phone Number'),
    city: Yup.string().required('Please Enter City Name'),
    zip: Yup.string().required('Please Enter Zip Code'),
    reCaptcha: Yup.string().required('recaptcha is a required field'),
  })
  return (
    <>
      <div className="ContactForm">
        <Container fixed>
          <div className="ContactForm_Box">
            <div className="ContactForm_Title">
              {Contact_Content?.CONTACT_FORM}
            </div>
            <div>
              <Formik
                initialValues={{
                  firstname: '',
                  lastname: '',
                  email: '',
                  phone: '',
                  zip: '',
                  city: '',
                  message: '',
                  reCaptcha: '',
                }}
                validationSchema={AccSchema}
                onSubmit={(values, {resetForm}) => {
                  handleSubmitForm(values, resetForm)
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="p-5 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-8">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="First Name"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="userfirstname"
                            name="firstname"
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="Last Name"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="lastname"
                            name="lastname"
                            type="text"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="Phone"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="phone"
                            name="phone"
                            type="number"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="Email"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="email"
                            name="email"
                            type="email"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="zip Code"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="zip"
                            name="zip"
                            type="number"
                            placeholder="Enter your first name"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <FormInput
                            title="City"
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            id="city"
                            name="city"
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="ContactForm_Message_Box flex flex-col">
                      <label className="FormInput_Title">Message :</label>
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="FormInput_Input"
                        value={values.message}
                        onChange={e => handleChange(e)}
                      />
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <button
                        className="ContactForm_Continue_btn"
                        // disabled={isLoading}
                        // className={`mb-2 ml-8 ${
                        //   isLoading
                        //     ? "bg-[#1010104D] cursor-not-allowed	"
                        //     : "bg-blue-500"
                        // } ${
                        //   isLoading ? "bg-[#1010104D]" : "hover:bg-yellow-600"
                        // } text-white font-bold py-2 px-4 rounded mt-8`}
                        onClick={() => {
                          setShowCode(true)
                        }}
                      >
                        Continue
                      </button>
                    </div>
                    {showCode && (
                      <div className="flex flex-col items-center">
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          size="normal"
                          sitekey={ReCAPTCHA_sitekey}
                          onChange={captchaCode => {
                            setFieldValue('reCaptcha', captchaCode)
                          }}
                        />
                        {errors?.reCaptcha && touched?.reCaptcha && (
                          <div
                            style={{fontSize: 14, textAlign: 'left'}}
                            className="FormInput_Error"
                          >
                            {errors?.reCaptcha}
                          </div>
                        )}
                        <button
                          type="submit"
                          style={{
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                          }}
                          className={`ContactForm_Submit_btn`}
                          disabled={isLoading}
                        >
                          SUBMIT
                        </button>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default ContactForm
