import './App.css'
import './Style/Dashboaed.css'
import './Style/Equipment.css'
import './Style/MachineDetails.css'
import './Style/ContactUs.css'
import './Style/Financing.css'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer} from 'react-toastify'
import Dashboard from './Pages/Dashboard/Dashboard'
import Equipment from './Pages/Equipment/Equipment'
import MachineDetails from './Pages/MachineDetails/MachineDetails'
import ContactUs from './Pages/Contact_US/contactUS'
import Financing from './Pages/Financing/Financing'
import AboutUS from './Pages/About_US/AboutUS'

import {BrowserRouter, Routes, Route} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/Equipment" element={<Equipment />}></Route>
          <Route
            path="/MachineDetails/:id"
            element={<MachineDetails />}
          ></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
          <Route path="/Financing" element={<Financing />}></Route>
          <Route path="/AboutUS" element={<AboutUS />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
