export const BASE_URL = 'https://dealerwebservice-api.azurewebsites.net/'
// export const PROD_URL = 'https://api.vizybility.net/'

const URL = BASE_URL

// Machine List
export const MACHINE_LIST = URL + 'DealerWebApp/GetInventory'
export const MACHINE_DETAILS =
  URL + 'DealerWebApp/GetInventoryDetailById?assetId='
export const GET_RELATED_POST =
  URL + 'DealerWebApp/GetInventoryByCategory?assetTypeId='
export const GET_MACHINES_DETAILS = URL + 'DealerWebApp/GetInventorySummaryById'

export const GET_CATEGORY_LIST =
  URL + 'DealerWebApp/GetTierWiseAssetCategorizationSummary'

export const POST_CONTACT_US = URL + 'DealerWebApp/SendContactEmail'
