import Machine from './Machine.jpg'
import Logo from './logo.png'
import AboutUs from './AboutUs.jpeg'
import Finance from './finance.png'
import Machine1 from './homeMachines/machine1.jpeg'
import Machine2 from './homeMachines/machine2.jpeg'
import Machine3 from './homeMachines/machine3.jpeg'

export const images = {
  Machine,
  Logo,
  AboutUs,
  Finance,
  Machine1,
  Machine2,
  Machine3,
}
