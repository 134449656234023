import { Box, CircularProgress, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllMachine } from "../../Api/apiHelper";
import EquipmentMachineList from "../../Components/CommonComponent/EquipmentMachineList/EquipmentMachineList";
import MachineList from "../../Components/CommonComponent/MachineList/MachineList";
import Loader from "../../Components/Loader";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import { Dashboard_Content } from "../../Utils/text_content";

const Equipment = () => {
  const limit = 9;
  const [MachineData, setMachineData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setIsLoading] = useState(false);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getMachinesList();
  }, [pageNumber]);

  const getMachinesList = () => {
    let params = {
      PageSize: limit,
      PageNumber: pageNumber,
      IsMachineListPage: true,
    };
    if (!infiniteLoading) {
      setIsLoading(true);
    }
    getAllMachine(params)
      .then((res) => {
        if (res.statusCode === 200) {
          const machineData = res?.data;
          setTotalRecords(machineData?.TotalRecords);
          if (parseInt(machineData?.PageNumber) == 1) {
            setMachineData(machineData?.machines);
            setPage(machineData?.PageNumber);
          } else {
            setMachineData([...MachineData, ...machineData?.machines]);
            setPage(machineData?.PageNumber);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setInfiniteLoading(false);
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout active_tab="Equipment">
      <Container maxWidth="md">
        <Box className="Dashboard_Box">
          <Grid container spacing={2}>
            <Grid item md={12} lg={7}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <EquipmentMachineList
                    Equipment_List={MachineData}
                    totalRecords={totalRecords}
                    setPageNumber={setPageNumber}
                    setInfiniteLoading={setInfiniteLoading}
                    page={page}
                  />
                  {infiniteLoading &&<Box> <CircularProgress /></Box>}
                </>
              )}
            </Grid>

            <Grid item md={12} lg={5}>
              <Box className="Dashboard_Item_Title">
                {Dashboard_Content?.LATEST_EQUIPMENT_LISTINGS}
              </Box>
              <MachineList />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </DashboardLayout>
  );
};
export default Equipment;
