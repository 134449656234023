const FormInput = ({
  title,
  values,
  errors,
  handleChange,
  touched,
  id,
  name,
  type,
  placeholder,
}) => {
  return (
    <>
      <div className="flex flex-col FormInput_Box">
        <label className="FormInput_Title">
         {title}<span className="FormInput_Title_Span text-[#FF0000]">*</span>
        </label>
        <input
          type={type}
          id={id}
          name={name}
          className="FormInput_Input"
          value={values?.[`${name}`]}
          onChange={(e) => handleChange(e)}
        />

        {errors?.[`${name}`] && touched?.[`${name}`] && (
          <div
            style={{ fontSize: 14, textAlign: "left" }}
            className="FormInput_Error"
          >
            {errors?.[`${name}`]}
          </div>
        )}
      </div>
    </>
  );
};
export default FormInput;
