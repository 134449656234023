import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const MachineBox = ({ item }) => {
  const navigate = useNavigate();
  console.log("item", item);
  return (
    <Box
      className="Dashboard_Item_Box"
      onClick={() => {
        const encodeURICom = encodeURIComponent(
          `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`
        );
        navigate(`/MachineDetails/${encodeURICom}`, {
          state: { id: item?.assetAdvertisementId },
        });
      }}
    >
      <Box className="Equipment_Machine_Title">
        {item?.year > 0 && item.year}{" "}
        {item?.manufacturer?.name && item.manufacturer.name}{" "}
        {item?.model?.number && item.model.number}
      </Box>
      <Box className="Equipment_Machine_Details_Box">
        <img
          src={item?.primary_image?.url}
          alt="Equipment_Machine_Img"
          className="Equipment_Machine_Img"
        />
        <Box className="Equipment_Machine_Details">
          <Box className="Dashboard_Machine_Description">
            {item?.meter_reading}
          </Box>
          <Box className="Dashboard_Machine_Description">
            {item?.category?.name}
          </Box>
          <Box className="Dashboard_Machine_Description">
            {item?.public_url}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const EquipmentMachineList = ({
  Equipment_List,
  totalRecords,
  setPageNumber,
  setInfiniteLoading,
  page,
}) => {
  const handleNextPage = () => {
    setInfiniteLoading(true);
    if (totalRecords >= Equipment_List?.length) {
      setPageNumber(page + 1);
    }
  };
  return (
    <InfiniteScroll
      dataLength={Equipment_List?.length}
      next={handleNextPage}
      hasMore={true}
      loader={null}
    >
      <div className="">
        {Equipment_List?.map((item) => (
          <MachineBox item={item} />
        ))}
      </div>
    </InfiniteScroll>
  );
};
export default EquipmentMachineList;
