import {Box} from '@mui/system'
import {Dashboard_Item_List} from '../../../Utils/text_content'
import MachineImg from '../../../Assets/Img/Machine.jpg'
import {useNavigate} from 'react-router-dom'

const MachineBox = ({item}) => {
  const navigate = useNavigate()
  return (
    <Box
      className="Dashboard_Item_Box"
      onClick={() => navigate('/MachineDetails')}
    >
      <img src={MachineImg} alt="" className="Dashboard_Item_Img" />
      <Box className="Dashboard_Machine_Title">{item?.title}</Box>
      <Box className="Dashboard_Machine_Description">{item?.description}</Box>
    </Box>
  )
}

const MachineList = () => {
  return Dashboard_Item_List?.map(item => {
    return <MachineBox item={item} />
  })
}

export default MachineList
