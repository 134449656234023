import { Container } from "@mui/material";
import AboutUSComponent from "../../Components/About_US/aboutUSComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
const AboutUS = () => {
  return (
    <>
     <DashboardLayout active_tab="AboutUS" >
        <Container maxWidth="md">
          <AboutUSComponent />
        </Container>
      </DashboardLayout>
    </>
  );
};
export default AboutUS;
