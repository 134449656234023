import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMachineDetailsByID } from "../../Api/apiHelper";
import Loader from "../../Components/Loader";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import {
  MachineDetails_Content,
  Dashboard_Content,
} from "../../Utils/text_content";

const MachineDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [machinesDetails, setMachinesDetails] = useState();
  const location = useLocation();

  useEffect(() => {
    getMachinesDetails();
  }, []);

  const MachineDetailsList = (data) => {
    console.log("data", data?.data);

    // return MachineDetails_Content?.details?.map((item) => {
    return (
      <>
        {" "}
        <Box className="Machine_Details_Description_Text">
          {data?.data?.year}
        </Box>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.configuration,
          }}
          className="Machine_Details_Description_Text"
        ></div>{" "}
      </>
    );
    // });
  };

  const EquipmentDetailsList = () => {
    return MachineDetails_Content?.Equiment_Data?.map((item) => {
      return (
        <Box className="Machine_Details_Description_Price">
          <Box className="Machine_Details_Description_Price_Title">
            {item?.Equiment_title}
          </Box>
          <Box className="Machine_Details_Description_Price_Dec">
            {item?.Equiment_SUbTitle}
          </Box>
        </Box>
      );
    });
  };

  const RelatedImgList = (images) => {
    console.log("images", images?.images);
    return (
      <Box className="RelatedImg_Box">
        {images?.images?.map((item) => {
          return <img src={item?.url} alt="" className="RelatedImg" />;
        })}
      </Box>
    );
  };

  console.log("machinesDetails", machinesDetails);

  const getMachinesDetails = () => {
    setIsLoading(true);
    getMachineDetailsByID(location?.state?.id)
      .then((res) => {
        if (res.statusCode === 200) {
          setMachinesDetails(res?.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Container maxWidth="md">
        {isLoading ? (
          <Loader />
        ) : (
          <Box className="MachineDetails_Box">
            <Grid container spacing={2}>
              <Grid item md={12} lg={8}>
                <Box className="MachineDetails_Title">
                  {MachineDetails_Content?.title}
                </Box>
                <Box>
                  <img
                    src={machinesDetails?.primary_image?.url}
                    alt=""
                    className="MachineDetails_Img"
                  />
                </Box>
                <MachineDetailsList data={machinesDetails} />
              </Grid>
              <Grid item md={12} lg={4}>
                <EquipmentDetailsList />
              </Grid>
            </Grid>
            <Box>
              <Box className="MachineDetails_Box_LARGER_VIEW_text">
                {Dashboard_Content?.LARGER_VIEW}
              </Box>
              <Box>
                <RelatedImgList images={machinesDetails?.images} />
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};
export default MachineDetails;
