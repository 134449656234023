import Footer from "./Footer/Footer";
import Header from "./Header/Header";
const DashboardLayout = ({ children,active_tab }) => {
  return (
    <>
      <Header active_tab={active_tab}/>
      {children}
      <Footer />
    </>
  );
};
export default DashboardLayout;
